.exercise-markdown img {
    max-width: 100%;
}

@font-face {
    font-family: 'quicksand';
    src: local('quicksand'), url(./fonts/quicksand.ttf) format('truetype');
}

.urgent-text {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
